import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchBox from './SearchBox'
import CloseIcon from '@mui/icons-material/Close';
import LoginIcon from '@mui/icons-material/Login';
export default function BasicGrid(props) {
  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "#F4CE6A", height: 50 }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ height: "100%",paddingRight:15}}>
        {/* -----------------------------------------------Menu icon and logo----------------------------------------------- */}
        <Grid item xs={2} md={2} style={{ height: "100%", paddingLeft:10 }}>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={()=>props.set_LeftNavigatorOpen(!props.LeftNavigatorOpen)}> {props.LeftNavigatorOpen?<CloseIcon className="menuicon"/>:<MenuIcon className="menuicon"/>} </IconButton>
        </Grid>
        {/* -----------------------------------------------SearchBox----------------------------------------------- */}
        <Grid item xs>
          <SearchBox> </SearchBox>
        </Grid>
        {/* -----------------------------------------------Login----------------------------------------------- */}
        <Grid className="login_container" item xs={2} md={2} container direction="row" justifyContent="flex-end" alignItems="center" style={{ height: "100%",paddingRight:10,minWidth:150}}>
          <span style={{fontWeight:500,cursor:"pointer",marginRight:5}} onClick={()=>props.set_LoginModalOpen(!props.LoginModalOpen)}>SIGNUP / LOGIN</span><LoginIcon/>
        </Grid>
      </Grid>
    </Box>
  );
}
