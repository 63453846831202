import { createTheme } from "@material-ui/core/styles";

// Create a theme instance.
const outerTheme1 = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#eaeaea',
    },
    secondary: {
      main: '#f50057',
    },
  },
  overrides: {
    MuiButton: {
      colorInherit: {
        backgroundColor: '#689f38',
        color: '#fff',
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
  },

})

outerTheme1.overrides.MuiTypography={
  root:{
    fontSize:12,
  }
}

outerTheme1.props.MuiAppBar={
  color:'red',
  
}


export default outerTheme1;