//https://firebase.google.com/docs/firestore/quickstart#node.js_1
import React, { Component } from 'react'

import { connect } from 'react-redux';
import { db } from "../firebase-config";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DropDown from "./DropDown"
import menu_submenu from '../../data/menu_submenu';
import ImageUpload from "./05ImageUpload"
class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {name_to_fetch:"",name: "1", description: "", unit_price: 0, discount: 0, selected_category: null,
      selected_sub_category: null, image: null
    };
  }
  componentDidMount() {
    console.log('didmount','test')
  }
componentDidUpdate(){
  console.log('didupdate','test22')
}

  getProducts = async () => {
    const ref = collection(db, 'products');
    const data = await getDocs(ref);
    data.docs.map(doc => {
      if (doc.exists) {
        const product = doc.data();
        if (product.name == this.state.name_to_fetch) {
          this.setState({
            id: doc.id,
            name: product.name,
            description: product.description,
            discount: product.discount,
            selected_category: product.category,
            unit_price: product.unit_price,
            selected_sub_category: product.sub_category,
            image: product.image
          });
        }
      } else {
        console.log("No such document!");
      }
    });
  }
  Update = async () => {
    const productDoc = doc(db, "products", this.state.id);
    const newData = { name: this.state.name };
    await updateDoc(productDoc, newData);
  };

  set_selcted_category = (val) => { this.setState({ selected_category: val }); this.setState({ selected_sub_category: null }) }
  set_selcted_sub_category = (val) => this.setState({ selected_sub_category: val })
  set_image = (val) => this.setState({ image: val })


  render() {
    console.log('render','test')
    var itemwidth = 300
    return (
      <Grid key={this.state.name} container direction="column" rowSpacing={1} justifyContent="flex-start" alignItems="center" style={{ width: "100%", height: "100%" }}>
        <Grid item style={{marginBottom:20}}>
          <TextField style={{ width: itemwidth }}
            id="standard-helperText"
            label="Type The Name To Fetch"
            defaultValue={this.state.name_to_fetch}
            variant="standard"
            onChange={(event) => this.setState({ name_to_fetch: event.target.value })}
          />
          <Button onClick={()=>this.getProducts()}>Fetch</Button>
        </Grid>
        <Grid item container direction="column" justifyContent="flex-start" alignItems="center" rowSpacing={1} style={{ width: 500, border: "3px solid #c4c4c4" }}>
          <Grid item style={{ width: itemwidth }}>
            <ImageUpload set_image={this.set_image} image={this.state.image} name_to_fetch_props={this.state.name_to_fetch}></ImageUpload>
          </Grid>
          <Grid item>
            <TextField style={{ width: itemwidth }}
              id="standard-helperText"
              label="Product Name"
              defaultValue={this.state.name}
              variant="standard"
              onChange={(event) => this.setState({ name: event.target.value })}
            />
          </Grid>
          <Grid item><TextField style={{ width: itemwidth }}
            id="outlined-multiline-flexible"
            multiline
            maxRows={8}
            label="Description"
            defaultValue={this.state.description}
            variant="standard"
            onChange={(event) => this.setState({ description: event.target.value })}
          /></Grid>
          <Grid item><TextField style={{ width: itemwidth }}
            id="standard-helperText"
            label="Unit Price"
            defaultValue={this.state.unit_price}
            variant="standard"
            onChange={(event) => this.setState({ unit_price: event.target.value })}
          />
          </Grid>
          <Grid item><TextField style={{ width: itemwidth }}
            id="standard-helperText"
            label="Discount"
            defaultValue={this.state.discount}
            variant="standard"
            onChange={(event) => this.setState({ discount: event.target.value })}
          />
          </Grid>
          <Grid item style={{ width: itemwidth }}>
            <DropDown itemwidth={itemwidth} text="Category" categories={Object.keys(menu_submenu)} set_selcted_category={this.set_selcted_category}></DropDown>
            {this.state.selected_category != null ? <p>{this.state.selected_category}</p> : null}
          </Grid>
          <Grid item style={{ width: itemwidth }}>
            {this.state.selected_category != null && Object.keys(menu_submenu[this.state.selected_category]).length > 0 ? <DropDown itemwidth={itemwidth} text="Sub Category" categories={Object.keys(menu_submenu[this.state.selected_category])} set_selcted_category={this.set_selcted_sub_category}></DropDown> : null}
            {this.state.selected_sub_category != null ? <p>{this.state.selected_sub_category}</p> : null}
          </Grid>

          <Grid item><Button variant="contained" style={{ width: itemwidth, marginBottom: 20, marginTop: 40 }} onClick={() => this.Update()}> Update </Button></Grid>
        </Grid>
      </Grid>
    )
  }
}
const maptstateToprop = (state) => {
  return {
    products: state.products,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_counter: (val) => dispatch({ type: "counter", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(Products);


