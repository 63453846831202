import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { connect } from "react-redux";
import "./02Product.css"
 function BasicGrid(props) {
    return (
        <Grid container direction="column" justifyContent="space-between" alignItems="center" style={{border: '1px solid rgb(201, 201, 201,0.08)',maxWidth:180}} sx={{boxShadow: 0 }}>
            <Grid item container direction="column" alignItems="center" style={{ width: "100%" }}>
                <img src={props.product.image} alt="Girl in a jacket" style={{ width: 50, height: 50, boxShadow:"3px 3px 8px 0px rgba(0,0,0,0)" }}></img>
                <p className="item_des" style={{ margin: 0, marginTop: 5, padding: "0px 5px", marginBottom: 10, color: "#262626", height:38}}>{props.product.name.length>30?props.product.name.slice(0,30)+"..":props.product.name}</p>
            </Grid>
            {/* Price and add to cart */}
            <Grid item container direction="column" alignItems="center" style={{ width: "100%"}}>
                <Grid item container direction="row" justifyContent="center" style={{ width: "100%" }}>
                    <p className="item_price" style={{ margin: 0, marginLeft: 0, padding: 0, marginBottom: 0 }}>{props.product.unit_price - props.product.discount}</p>
                    <p className="item_price2" style={{ margin: 0, marginLeft: 10, padding: 0, marginBottom: 10}}>{props.product.unit_price}</p>
                </Grid>
                <Button onClick={()=>{
                    var temp=[...props.click_products]
                    if (temp.some(e => e.name === props.product.name)) {
                        //console.log("ducplicate found")
                      }
                      else{
                        temp.push(props.product)
                        var temp2={...props.product_count} // to set product count of the product name to 1
                        temp2[props.product.name]=1
                        props.Set_product_count(temp2)
                        props.Set_click_products(temp)
                      }
                }} fullWidth style={{  backgroundColor: "#e5e5e5", borderRadius: 0,maxWidth:200,fontSize:12,height:30 }}><AddShoppingCartIcon style={{ marginRight: 5,width:15 }} />Add to cart</Button>
            </Grid>
        </Grid>
    );
}
const maptstateToprop = (state) => {
    return {
        click_products:state.click_products,
        product_count: state.product_count,
    };
  };
  const mapdispatchToprop = (dispatch) => {
    return {
      Set_click_products: (val) => dispatch({ type: "click_products", value: val }),
      Set_product_count: (val) => dispatch({ type: "product_count", value: val }),
    };
  };
  export default connect(maptstateToprop, mapdispatchToprop)(BasicGrid);
  