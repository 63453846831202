import * as React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import CartSelected from "./CartSelected";
import Button from '@mui/material/Button';
import CheckOutModal from "./CheckOutModal"
function RightDrawer(props) {
    const [CheckOutModalOpen,set_CheckOutModalOpen]=React.useState(false)
     var total=0
     if(Object.keys(props.product_count).length>0){
          props.click_products.map(item=>{
               console.log(item.unit_price,item.discount,props.product_count[item.name])
               total=total+(parseInt(item.unit_price - item.discount)*props.product_count[item.name])
          })
     }
     return (
          <Grid container direction="column" alignItems="center" style={{ width: "100%", height: "100%", padding: 0, backgroundColor: "rgb(211, 211, 211,0.1)", boxShadow: "3px 3px 8px 0px rgba(0,0,0,0.3)", padding: 0 }}>
               <Grid item style={{ width: "100%", height: props.rightbarHeight - 31, padding: 0, overflow: "scroll" }}>
                    <CartSelected> </CartSelected>
               </Grid>
               <Grid item container direction="row" justifyContent="space-around" alignItems="center" style={{ width: "100%", height: 30, padding: 0, marginBottom: 0, overflow: 'hidden' }}>
                    <Grid item xs={6}><Button variant="contained" fullWidth style={{ borderRadius: 0,boxShadow:"none", opacity:1,fontSize:12,height:"100%",opacity:0.9}} onClick={()=>set_CheckOutModalOpen(true)}>Place Order</Button></Grid>
                    <Grid item xs={6}><Button variant="contained" fullWidth style={{ borderRadius: 0,boxShadow:"none",fontSize:12,height:"100%",fontWeight:600}}>{"৳ "+total}</Button></Grid>
               </Grid>
               <CheckOutModal CheckOutModalOpen={CheckOutModalOpen} set_CheckOutModalOpen={set_CheckOutModalOpen}></CheckOutModal>
          </Grid>
     );
}
const maptstateToprop = (state) => {
     return {
          click_products: state.click_products,
          product_count:state.product_count,
     };
};
const mapdispatchToprop = (dispatch) => {
     return {
          Set_click_products: (val) => dispatch({ type: "click_products", value: val }),
     };
};
export default connect(maptstateToprop, mapdispatchToprop)(RightDrawer);