import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore } from 'redux';
import reducer from './reducer';
import { ThemeProvider } from '@material-ui/core/styles';
import {Provider} from 'react-redux';
import outerTheme1 from './theme'
const store=createStore(reducer);
ReactDOM.render(<Provider store={store}><ThemeProvider theme={outerTheme1}><App /></ThemeProvider></Provider>,document.getElementById('root'));