//https://firebase.google.com/docs/firestore/quickstart#node.js_1
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { db } from "../firebase-config";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, } from "firebase/firestore";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LeftNavigator from "../../LeftNavigator/LeftNavigator"

import { Link } from '@mui/material';


import { DataGrid } from "@material-ui/data-grid";
class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    removeProduct = async (id) => {
        const productDoc = doc(db, "products", id);
        await deleteDoc(productDoc);
    };

    removeProduct1 = async (id) => {
        console.log('delete id ',id)
    };


  
    render() {

        const rows = [];
        const product=this.props.productList;
        console.log('product',product)
        console.log('rimel',product.map((item)=>item.name))
        product &&
        product.forEach((item) => {
            rows.push({
              id: item.id,
              img: item.image,
              name: item.name,
              category: item.category,
              sub_category: item.sub_category
              
              
            });
          });
        
          
          
          const columns = [
            // { field: "id", headerName: "ID", width: 200  },
            {
              field: "img",
              headerName: "Products",
              width: 135 ,
              
             
              renderCell: (params) => {
                return (
                  <div className="userListUser">
                    <img style={{height:'90px',width:'90px',padding:'10px',borderRadius:'50%',objectFit:'cover'
                    ,display:'flex',alignItems:"center",
                }} src={params.row.img} alt="" />
                    {params.row.username}
                  </div>
                );
              },
            },
            { field: "name", headerName: " Name", width: 200, },
           
            {
              field: "category",
              headerName: "Category",
              width: 250,
            },
            {
              field: "sub_category",
              headerName: "subcategory",
              width: 160,
            },
            {
              field: "Action",
              headerName: "Action",
              width: 150,
              renderCell: (params) => {
                return (
                  <>
                    {/* <Link to={"/user/" + params.row.id}> */}
                      <button onClick={() => this.removeProduct1(params.row.id)} className="userListEdit">remove</button>
                    {/* </Link> */}
                    
                  </>
                );
              },
            },
          ];
        return (
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ width: "100%", height: "100%"}}>
                <Grid item xs={4} style={{ height: window.innerHeight - 100, overflow: 'scroll' }}>
                    <LeftNavigator> </LeftNavigator>
                </Grid>
                {/* <Grid xs={8} item container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    {this.props.productList.map(item => <Grid item container columnSpacing={2} justifyContent="flex-start" alignItems="center">
                        <Grid item><p style={{ margin: 0, marginTop: 5 }}>{item.name}</p></Grid>
                        <Grid item> <img src={item.image} alt="" id="img" className="img" /></Grid>
                        <Grid item><Button onClick={() => this.removeProduct(item.id)} style={{color:"red",marginLeft:40}}>Remove</Button></Grid>
                    </Grid>)}
                </Grid> */}

                 <Grid xs={8} item container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    
                    
                    {this.props.productList.map(item =>
                      <Grid xs={4} item container direction="row" columnSpacing={2} justifyContent="flex-start" alignItems="flex-start" style={{ borderBottom: "1px solid rgba(0,0,0,0.1)", margin: 0, padding: 5, width: "50%"}}> 
                      
                    <Grid xs={6} item >
                         <Grid item>  <img src={item.image}  alt="" id="img"  height='60px' width='60px'/></Grid>
                         <Grid item><p style={{ margin: 0, marginTop: 5 ,width:'150px'}}>{item.name} </p></Grid>
                     </Grid>
                       
                      <Grid  item> <Button onClick={() => this.removeProduct(item.id)} style={{color:"red",marginLeft:50,marginTop:'25%'}}>Remove </Button></Grid>
                    </Grid>)}
                </Grid>
                
                
                
                
                
                
{/*                 
                <Grid xs={8}  container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{height:'100vh'}}>
                                <DataGrid
                                rows={rows}
                                disableSelectionOnClick
                                columns={columns}
                                pageSize={10}
                                rowHeight={80}
                                rowsPerPageOptions={[10,20,30,100]}
                               // checkboxSelection
                            />
                 </Grid> */}

                     

            </Grid>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        sub_category: state.sub_category,
        productList: state.productList,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_counter: (val) => dispatch({ type: "counter", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(Products);


