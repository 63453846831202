import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ImageUpload from "./05ImageUpload";
import { useEffect, useState } from "react";
import { db } from "../Components/firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

import { connect } from 'react-redux';
import { style,makeStyles} from "@material-ui/core";
import { fontFamily, height } from "@mui/system";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Whole Sale BD
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}


const styles = theme => ({
  textField: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',            
      paddingBottom: 0,
      marginTop: 0,
      fontWeight: 500
  },
  input: {
      color: 'white'
  }
});

const useStyles= makeStyles({
  terms :{
    // 
   fontFamily: 'system-ui',
   fontSize:'18px',
   textDecorationLine:'underline',
   cursor:'pointer',
   margin:'0px 2px',
   color:'darkcyan',
  '&:hover': {
    color:'blue',
  }},

  policy : {
  fontFamily: 'system-ui',
  fontSize:'18px',
  textDecorationLine:'underline',
  cursor:'pointer',
  margin:'0px 2px',
  color:'darkcyan',
  '&:hover': {
    color:'blue',
  },
 
},
 
btn:{
  background:'darkcyan',
  color:'white'
},
row0:{
  height:'2px',
  width:'120px',
  // display:'flex',
  // flexDirection:'row',
  // justifyContent:'center',
  // alignItems:'center',
  // margin:'20px',
  background:'red'
},
row1:{
  height:'2px',
  width:'120px',
  // display:'flex',
  // flexDirection:'row',
  // justifyContent:'center',
  // alignItems:'center',
  // margin:'20px',
  background:'red',
},

login: {
  color:"darkcyan",
  textDecoration:"none",
  cursor:'pointer',
  margin:"5px",
  fontFamily:'system-ui',
  fontSize:'18px',
  fontWeight:'700',
  '&:hover': {
    color:'blue',
  }
},
logintext: {
  color:'#181d6b',
  textDecoration:"none",
  cursor:'pointer',
  margin:"5px",
  // fontFamily:'system-ui',
  fontSize:'16px',
  fontWeight:'700'
}

,

TextField :{
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',            
  paddingBottom: 1,
  marginTop: 0,
  fontWeight: 500,
  height:'100px'
},
input: {
  color: 'white'
}




})

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem',
        },
      },
    },
  },
});

// const theme = createTheme();

export default function SignIn(props) {

const styles=useStyles();

  const [u_name, setU_name] = useState('');
  const [email, setEmail] = useState('');
  const [password, setpassword] = useState('');
  const [cpassword, setcpassword] = useState('');
  const [address, setaddress] = useState('');
  const [p_number, setp_number] = useState('');
  const [image, setimage] = useState('');
  const addProduct = async () => {
    const productsCollectionRef = collection(db, "users");
    console.log("function");
    var data = {
      u_name: u_name,
      email: email,
      password: password,
      cpassword: cpassword,
      address: address,
      p_number: p_number,
      image: image,
    };
    console.log(data, "data");
    await addDoc(productsCollectionRef, data);
  };
  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        style={{ backgroundColor: "snow", borderRadius: "20px" ,height:'auto'}}
      >
        <CssBaseline />
        <Box style={{ fontWeight: '700' }}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography sx={{ mt: 1, mb: 4 }} component="h1" variant="h5" style={{fontWeight:'700'}}>
            <span style={{color:'#181d6b'}}>Whole</span>  <span style={{color:'#4cd8ac'}}>Sale BD</span>
          </Typography>
          
          {/* <Grid >
            <ImageUpload set_image={setimage}></ImageUpload>
          </Grid> */}
          <p    className={styles.logintext}>
          By signing up, you agree to our 
        <span className={styles.terms} >
          terms
        </span>
        &
        <span className={styles.policy}>
        policy
        </span>
      </p>
          <Box
            component="form"
            // onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField 
             
              size='small'
              margin="normal"
              required
              fullWidth
              id="user_name"
              label="user name"
              name="user_name"
              autoComplete="user_name"
              autoFocus
              onChange={(event) => setU_name(event.target.value)}
            />
            <TextField
             size='small'
              autoFocus
              margin="normal"
              required
              fullWidth
              id="standard-helperText"
              label="Email"
              defaultValue=""
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField 
             size='small'
              margin="normal"
              required
              fullWidth
              name="password"
              label="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => setpassword(event.target.value)}
            />
            {/* <TextField
              margin="normal"
              required
              fullWidth
              name="Confirm password"
              label="Confirm password"
              type="password"
              id="Cpassword"
              autoComplete="current-password"
              onChange={(event) => setcpassword(event.target.value)}
            /> */}
             <TextField size='small'
              margin="normal"
              required
              fullWidth
              name="Address"
              label="Address"

              id="Address"
              autoComplete="current-password"
              onChange={(event) => setaddress(event.target.value)}
            />
            <TextField size='small'
              margin="normal"
              required
              fullWidth
              name="Phone Number"
              label="Phone Number"
              id="Phone Number"
              autoComplete="current-password"
              onChange={(event) => setp_number(event.target.value)}
            /> 
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button className={styles.btn}
              
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 4 }}
              onClick={() => addProduct()}
            >
              Register
            </Button>
           
            <Grid >
           
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
            <Typography className={styles.row0}></Typography>
            <Typography  > Or</Typography>
            <Typography className={styles.row1}></Typography>
            </Grid>
           
              <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 1, mb: 4 }}>

                <Link className={styles.logintext} href="#" variant="body2">
                  Already have an account? <span className={styles.login}>Login</span>
                </Link>
              </Grid>
           
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}

