import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux';
import { db } from "../Components/firebase-config";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, } from "firebase/firestore";
import "./ExpandableList.scss"
function NestedList(props) {
  var config = { fontSize1: 14, fontSize2: 10, fontColor: "rgb(112, 112, 112,0.5)", iconSize: 18, iconColor: "rgb(112, 112, 112)", close_iconColor: "rgb(160, 36, 36)" }
  const productsCollectionRef = collection(db, "products");
  const [open, setOpen] = React.useState(false);
  const handleClick = () => { setOpen(!open)};
  const HandleSubCatClick=async (sub_category)=>{
    const data = await getDocs(productsCollectionRef);
    var productList=data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    props.Set_productList(productList.filter(item=>item['sub_category']==sub_category))
    props.Set_sub_category(sub_category)
  }
  return (
    <List className="list1" sx={{ width: '100%', maxWidth: 360,padding:0}} component="nav" aria-labelledby="nested-list-subheader">
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={props.item[0]} />
        {Object.entries(props.item[1]).length>0?open ? <ExpandLess /> : <ExpandMore />:null /* If open use the ExpandLess icon else use the ExpandMore icon */ }
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className="list2">
        {Object.entries(props.item[1]).map(item=><ListItemButton sx={{ pl: 4 }} onClick={()=>HandleSubCatClick(item[0])}>
            <ListItemText primary={item[0]} />
          </ListItemButton>)}
        </List>
      </Collapse>
    </List>
  );
}
const maptstateToprop = (state) => {
  return {
    productList: state.productList,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      Set_category: (val) => dispatch({ type: "category", value: val }),
      Set_sub_category: (val) => dispatch({ type: "sub_category", value: val }),
      Set_productList: (val) => dispatch({ type: "productList", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(NestedList);


