import * as React  from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from "react-redux";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.90),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '58ch',
      },
    },
  },
}));
function SearchAppBar(props) {
  return (
       <Search>
            <SearchIconWrapper>
                 <SearchIcon className="searchicon" />
            </SearchIconWrapper>
            <StyledInputBase
                 placeholder="Search…"
                 inputProps={{ "aria-label": "search" }}
                 size={4}
                 onChange= {(e)=> props.Set_Appbar_Search(e.target.value)}
            />
       </Search>
  );
}
const maptstateToprop = (state) => {
  return {
  };
};
const mapdispatchToprop = (dispatch) => {
  return {
    Set_Appbar_Search: (val) => dispatch({ type: "Appbar_Search", value: val }),
  };
};
export default connect(maptstateToprop, mapdispatchToprop)(SearchAppBar);