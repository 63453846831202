import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import { style,makeStyles} from "@material-ui/core";

function Copyright(props) {
  return (
    <Typography style={{color:'black', fontWeight:'700',}} variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Whole Sale BD
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles= makeStyles({
  terms :{
    // 
   fontFamily: 'system-ui',
   fontSize:'18px',
   textDecorationLine:'underline',
   cursor:'pointer',
   margin:'0px 2px',
   color:'darkcyan',
  '&:hover': {
    color:'blue',
  }},

  policy : {
  fontFamily: 'system-ui',
  fontSize:'18px',
  textDecorationLine:'underline',
  cursor:'pointer',
  margin:'0px 2px',
  color:'darkcyan',
  '&:hover': {
    color:'blue',
  },
 
},
 
btn:{
  background:'darkcyan',
  color:'white'
},
row0:{
  height:'2px',
  width:'120px',
  // display:'flex',
  // flexDirection:'row',
  // justifyContent:'center',
  // alignItems:'center',
  // margin:'20px',
  background:'red'
},
row1:{
  height:'2px',
  width:'120px',
  // display:'flex',
  // flexDirection:'row',
  // justifyContent:'center',
  // alignItems:'center',
  // margin:'20px',
  background:'red',
},

login: {
  color:"darkcyan",
  textDecoration:"none",
  cursor:'pointer',
  margin:"5px",
  fontFamily:'system-ui',
  fontSize:'14px',
  fontWeight:'700',
  '&:hover': {
    color:'blue',
  }
},
logintext: {
  color:'#181d6b',
  textDecoration:"none",
  cursor:'pointer',
  margin:"5px",
  // fontFamily:'system-ui',
  fontSize:'14px',
  fontWeight:'700'
}

,

TextField :{
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',            
  paddingBottom: 0,
  marginTop: 0,
  fontWeight: 500
},
input: {
  color: 'white'
}




})



const theme = createTheme();

export default function SignIn(props) {
  const styles=useStyles();

  const [cookies, setCookie] = useCookies(['name']);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
      var user_name=data.get('user_name')
      var password=data.get('password')
      if(user_name=='admin'){
        setCookie('name', 'admin', { path: '/' ,maxAge :3600});
        props.set_logged_user('admin')

        props.set_LoginModalOpen(false)
      }
      else{
        alert("Wrong username or Password! Please try again")
      }
  };




  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" style={{ backgroundColor: "snow", borderRadius: "20px" ,height:'auto'}}>
        <CssBaseline />
        <Box style={{ fontWeight: '700' }} sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography sx={{ mt: 0.5, mb: 2 }} component="h1" variant="h5" style={{fontWeight:'700'}}>
            <span style={{color:'#181d6b'}}>Whole</span>  <span style={{color:'#4cd8ac'}}>Sale BD</span>
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="user_name" label="user name" name="user_name" autoComplete="user_name" autoFocus/>
            <TextField margin="normal" required fullWidth name="password" label="password" type="password" id="password" autoComplete="current-password"/>
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me"/>
            <Button className={styles.btn} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Sign In</Button>
            <Grid container>
              <Grid item xs>
                <Link className={styles.logintext} href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link  href="#" variant="body2"  onClick={()=>{props.set_LoginModalOpen(!props.LoginModalOpen);props.set_SignupModalOpen(true)}}>
                  <span className={styles.login}>Don't have an account? Sign Up</span>
                </Link>

                {/* <Grid item xs={2} md={2} container direction="row" justifyContent="flex-end" alignItems="center" style={{ height: "100%",paddingRight:10}}>
          <span style={{fontWeight:500,cursor:"pointer"}} onClick={()=>props.set_SignupModalOpen(!props.SignupModalOpen)}>Login</span>
        </Grid> */}

              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}