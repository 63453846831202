import * as React from 'react';
import { styled, Box } from '@mui/system';
import ModalUnstyled from '@mui/core/ModalUnstyled';
import Login from './Login';
import SignUp from "./02SignUp"
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
export default function ModalUnstyledDemo(props) {
  const handleClose = () => {props.set_LoginModalOpen(false);props.set_SignupModalOpen(false)};
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.LoginModalOpen}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        {props.SignupModalOpen==true?<SignUp></SignUp>:<Login set_logged_user={props.set_logged_user} set_LoginModalOpen={props.set_LoginModalOpen} set_SignupModalOpen={props.set_SignupModalOpen}></Login>}
      </StyledModal>
    </div>
  );
}