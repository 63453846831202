import React, { Component } from 'react'
import { connect } from 'react-redux';
import menu_submenu from "../data/menu_submenu";
import Grid from '@mui/material/Grid';
import ExpandableList from './ExpandableList'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { temp: 'state variable' };
  }
  render() {
    return (
        <Grid className="leftNavigator" container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{width:"100%"}}>
          {
              Object.entries(menu_submenu).map(item=><ExpandableList item={item}></ExpandableList>)
          }
        </Grid>          
    )
  }
}
const maptstateToprop = (state) => {
  return {
    counter: state.counter,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_counter: (val) => dispatch({ type: "counter", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);


