import React, { Component } from "react";
import "./ImageUpload.css";
export class App extends Component {
  constructor(props) {
    super(props)
    this.state = { profileImg: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
  }
  //-----Image Handler ends here
  imageHandler = e => {
    var self=this
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        self.props.set_image(reader.result)
        this.setState({ profileImg: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    //var file = e.target.files[0];
    
  };
  
  componentDidMount() {
    
  }
  //-----Image Handler ends here
  render() {
    const { profileImg } = this.state;
    return (
        <div>
          <div>
            <img src={profileImg} alt="" id="img" className="img" />
          </div>
          <input type="file" accept="image/*" name="image-upload" id="input" onChange={this.imageHandler}/>
            <label className="image-upload" htmlFor="input"> Select Image </label>
        </div>
    );
  }
}

export default App;
// Add the following css in ImageUpload.css
/*
  .img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  #input[type="file"] {
    display: none;
  }
  .image-upload {
    height: 20px;
    color: black;
    padding: 5px 28px;
    background-color: #E5E5E5;
    text-align: center;
    cursor: pointer;
  }
  
*/
// https://www.youtube.com/watch?v=o2nmgbZaGMw