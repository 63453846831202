let initialState = {
  counter: 0,
  productList: [],
  category:"",
  sub_category:"Milk and Bakery", // Default
  user: '',
  click_products:[],
  product_count:{},
  Appbar_Search:'',

};
const reducer = (state = initialState, action) => {
  if (action.type === "product_count") {
    console.log(action.value)
    return { ...state, product_count: action.value }
  }

  if (action.type === "productList") {
    console.log(action.value)
    return { ...state, productList: action.value }
  }
  if (action.type === "category") {
    console.log(action.value)
    return { ...state, category: action.value }
  }
  if (action.type === "sub_category") {
    console.log(action.value)
    return { ...state, sub_category: action.value }
  }
  if(action.type === "counter") {
    console.log(action.value,'counter')
    return { ...state, counter: action.value }
  }
  if(action.type === "user") {
    console.log(action.value,'user')
    return { ...state, user: action.value }
  }
  if(action.type === "click_products") {
    // console.log(action.value,'action')
    // if(!state.click_products.includes(action.value))
    return { ...state, click_products: action.value }
  }
  if (action.type === "Appbar_Search") {
    //console.log(action.value)
    return { ...state, Appbar_Search: action.value }
  }
  return state;
};
export default reducer;


