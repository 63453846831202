import * as React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Icon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
function RightDrawer(props) {
     React.useEffect(() => {
          var temp = {}
          props.click_products.map(item => { temp[item.name] = 1 })
          props.Set_product_count(temp)
     }, [])
     var typography1 = { fontSize1: 14, fontSize2: 10, fontColor: "rgb(112, 112, 112,0.5)", iconSize: 18, iconColor: "rgb(112, 112, 112)", close_iconColor: "rgb(160, 36, 36)" }
     return (
          <div style={{ width: "100%", height: "100%" }}>
               {props.click_products.map(item => <Grid className="product_container" style={{ borderBottom: "1px solid rgba(0,0,0,0.1)", margin: 0, padding: 5, width: "100%" }}
                    direction="row"
                    container
                    rowSpacing={4}
                    columnSpacing={{ xs: 1, sm: 2, md: 4 }}
               >
                    {/*----------------------------------------------------------------------------------------First icon group starts */}
                    <Grid justifyContent="flex-start" alignItems="center" item container style={{ padding: 0, width: typography1.iconSize + 10 }} direction="column">
                         <Grid item>  <Icon style={{ cursor: 'pointer', color: typography1.iconColor, width: typography1.iconSize }} onClick={() => {
                              var temp = { ...props.product_count };
                              if (item.name in temp) { temp[item.name] = temp[item.name] + 1 }
                              else { temp[item.name] = 2 }
                              props.Set_product_count(temp)
                         }
                         }></Icon></Grid>
                         <Grid item><span style={{ fontColor: typography1.fontColor, fontSize: typography1.fontSize2 }}>{item.name in props.product_count ? props.product_count[item.name] : 1}</span> </Grid>
                         <Grid item><RemoveIcon style={{ cursor: 'pointer', color: typography1.iconColor, width: typography1.iconSize }} onClick={() => {
                              var temp = { ...props.product_count };
                              if (item.name in temp) {
                                   if (temp[item.name] > 1) {
                                        temp[item.name] = temp[item.name] - 1
                                        props.Set_product_count(temp)
                                   }
                              }
                         }
                         }></RemoveIcon> </Grid>
                    </Grid>
                    {/*----------------------------------------------------------------------------------------First icon group ends */}

                    {/*----------------------------------------------------------------------------------------Image and product Name starts */}
                    <Grid item xs container direction="row" style={{ padding: 0 }} justifyContent="center" alignItems="center">
                         <Grid item xs={4} item container columnGap={2} style={{ padding: 0 }} direction="row" justifyContent="center" alignItems="center">
                              <Grid item style={{ width: 25 }}> <img src={item.image} height='auto' width='25px' /> </Grid>
                              <Grid item container rowGap={0.5} direction="column" justifyContent="center" alignItems="center">
                                   <Grid item>  <span style={{ fontColor: typography1.fontColor, fontSize: typography1.fontSize1 }}>{item.name}</span> </Grid>
                                   <Grid item>  <span className="price" style={{ fontColor: typography1.fontColor, fontSize: typography1.fontSize2 }}>{item.unit_price - item.discount}</span></Grid>
                              </Grid>
                         </Grid>
                         <Grid item xs={4}
                              container rowGap={0.5} style={{ padding: 0 }}
                              direction="column"
                              justifyContent="center"
                              alignItems="center">
                              <Grid item> <span style={{ fontColor: typography1.fontColor, fontSize: typography1.fontSize1 }}>Total</span> </Grid>
                              <Grid item> <span className="price" style={{ fontColor: typography1.fontColor, fontSize: typography1.fontSize2 }}>{item.name in props.product_count ? props.product_count[item.name] * (parseInt(item.unit_price) - parseInt(item.discount)) : (parseInt(item.unit_price) - parseInt(item.discount))}</span></Grid>
                         </Grid>
                    </Grid>
                    {/*----------------------------------------------------------------------------------------Image and product Name ends */}
                    <Grid justifyContent="flex-start" alignItems="center" item container direction="row" style={{ padding: 0, width: typography1.iconSize + 10 }} >
                         <Grid item> <CloseIcon style={{ cursor: 'pointer', color: typography1.close_iconColor, width: typography1.iconSize }} onClick={() => {
                              var temp = props.click_products.filter(element => element.name != item.name)
                              props.Set_click_products(temp)
                         }
                         }
                         /> </Grid>
                    </Grid>
               </Grid>)}
          </div>
     );
}


const maptstateToprop = (state) => {
     return {
          click_products: state.click_products,
          product_count: state.product_count,
     };
};
const mapdispatchToprop = (dispatch) => {
     return {
          Set_click_products: (val) => dispatch({ type: "click_products", value: val }),
          Set_product_count: (val) => dispatch({ type: "product_count", value: val }),
     };
};
export default connect(maptstateToprop, mapdispatchToprop)(RightDrawer);