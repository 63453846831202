import './App.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import AppBar from './AppBar/AppBar';
import LeftNavigator from './LeftNavigator/LeftNavigator';
import Grid from '@mui/material/Grid';
import Slide from "@mui/material/Slide";
import LoginModal from './Login/LoginModal'
import ProductsContainer from './Components/Products/01ProductsContainer';
import AdminContainer from './Components/Admin/01AdminContainer';
import RightBar from './RightBar/RightBar';
import Checkout2 from './RightBar/Checkout2';
import { withCookies, Cookies } from 'react-cookie';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { temp: 'state variable', LeftNavigatorOpen: true, LoginModalOpen: false, SignupModalOpen: false, logged_user: 'admins', resized: 10, };
  }
  componentDidMount() {
    console.log(this.props.cookies.get('name'), "cookie")
    // this.props.cookies.set('name', "rimel", { path: '/',maxAge:3600});
    this.props.Set_user(this.props.cookies.get('name'));
    window.addEventListener('resize', this.handleResize)
  }
  set_LeftNavigatorOpen = (val) => {
    this.setState({ LeftNavigatorOpen: val })
  }
  set_LoginModalOpen = (val) => {
    this.setState({ LoginModalOpen: val })
  }
  set_SignupModalOpen = (val) => {
    this.setState({ SignupModalOpen: val })
  }
  set_logged_user = (val) => {
    this.setState({ logged_user: val })
  }
  handleResize = () => {
    this.setState({ resized: window.innerWidth + window.innerHeight })
    this.props.Set_counter('width: ' + window.innerWidth);
    if(window.innerWidth<600){this.setState({ LeftNavigatorOpen: false })}
    else{this.setState({ LeftNavigatorOpen: true })}
    //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
  }
  render() {
    var app_bar_height = 50
    return (
      <div style={{ width: window.innerWidth, height: window.innerHeight }}>
        
        <AppBar set_LeftNavigatorOpen={this.set_LeftNavigatorOpen} LeftNavigatorOpen={this.state.LeftNavigatorOpen} set_LoginModalOpen={this.set_LoginModalOpen} LoginModalOpen={this.state.LoginModalOpen}></AppBar>
        {/* <SignUp1/> */}
        {/* <Checkout2/> */}
        {this.props.user != 'admin' ? <Grid className="container_for_left_nav_and_products" container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Slide direction="right" in={this.state.LeftNavigatorOpen} mountOnEnter unmountOnExit>
            <Grid item style={{ width: 250, borderRight: '1px solid #eaeaea', height: window.innerHeight - app_bar_height, overflow: 'scroll' }}>
              <LeftNavigator LeftNavigatorOpen={this.state.LeftNavigatorOpen}></LeftNavigator>
            </Grid>
          </Slide>

          <Grid item xs style={{ height: window.innerHeight - app_bar_height }} key={this.state.resized}>
            <ProductsContainer></ProductsContainer>
          </Grid>
          <Slide direction="left" in={this.props.click_products.length > 0} mountOnEnter unmountOnExit>
            <Grid item style={{ width: 350, height: window.innerHeight - app_bar_height}} rightbarHeight={window.innerHeight - app_bar_height}>
              <RightBar rightbarHeight={window.innerHeight - app_bar_height}></RightBar>
            </Grid>
          </Slide></Grid> : <AdminContainer></AdminContainer>}
        <LoginModal set_logged_user={this.set_logged_user} set_LoginModalOpen={this.set_LoginModalOpen} LoginModalOpen={this.state.LoginModalOpen} set_SignupModalOpen={this.set_SignupModalOpen} SignupModalOpen={this.state.SignupModalOpen}></LoginModal>
      </div>
    )
  }
}
const maptstateToprop = (state) => {
  return {
    MYcounter: state.counter,
    productList: state.productList,
    click_products: state.click_products,
    user: state.user
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    Set_counter: (val) => dispatch({ type: "counter", value: val }),
    Set_user: (val) => dispatch({ type: "user", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(withCookies(App));

// https://mui.com/api/slide/
