import * as React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Icon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
function Checkout(props) {
     var typography1 = { fontSize1: 14, fontSize2: 10, fontColor: "rgb(112, 112, 112,0.5)", iconSize: 18, iconColor: "rgb(112, 112, 112)", close_iconColor: "rgb(160, 36, 36)" }
     return (
          <div style={{ width: "80%", height: "90%", backgroundColor: "rgb(242, 242, 242)" }}>
               <Grid xs={12} style={{height:50,backgroundColor:"#333333"}}> <p style={{ fontWeight: 'bolder',color:"white",margin:0,padding:5,textAlign:'center',fontSize:30 }}>Checkout</p> </Grid>
               <Grid sx={{ padding: 4 }}
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="flex-start"
                    direction="row"
               >
                    {/* ----------------- part 1------------ */}
                    <Grid sx={{ background: 'white', padding: 1 }}
                         xs={7}
                         container
                         justifyContent="center"
                         alignItems="center"
                         direction="column"
                    >
                         {/* <h1>part 1</h1> */}

                         <Grid
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                         >
                              <label style={{ textAlign: "center", fontWeight: 'bolder', margin: '30px 0px 0px 20px', marginBottom: '-10px' }}>
                                   Shipping Address</label>
                         </Grid>

                         <Grid sx={{ padding: "10px" }}
                              container
                              justifyContent="space-between"
                              alignItems="center"
                         >
                              <TextField sx={{ width: "45%", maxWidth: '100%', padding: "10px" }}
                                   label="First Name"
                                   variant="outlined"
                                   size='small'
                              />

                              <TextField sx={{ width: "45%", maxWidth: '100%', padding: "10px" }}
                                   label="Last Name"
                                   variant="outlined"
                                   size='small'
                              />
                         </Grid>

                         <Grid
                              container
                              justifyContent="center"
                              alignItems="center">

                              <FormControl fullWidth sx={{ m: 1, padding: "10px" }}>
                                   <InputLabel htmlFor="outlined-adornment-amount">Address Line 1</InputLabel>
                                   <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={' '}
                                        label="Address Line 1"
                                        size='small'
                                   />
                              </FormControl>


                              <FormControl fullWidth sx={{ m: 1, padding: "10px" }}>
                                   <InputLabel htmlFor="outlined-adornment-amount">Address Line 2</InputLabel>
                                   <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={' '}
                                        label="Address Line 2"
                                        size='small'
                                   />
                              </FormControl>

                              <FormControl fullWidth sx={{ m: 1, padding: "10px" }}>
                                   <InputLabel id="demo-simple-select-label">City</InputLabel>
                                   <Select
                                        value={'Select City'}
                                        label="City"
                                        // onChange={handleChange}
                                        size='small'
                                   >
                                        <MenuItem value={10}>	Dhaka</MenuItem>
                                        <MenuItem value={20}>	Chittagong</MenuItem>
                                        <MenuItem value={30}>	Khulna</MenuItem>
                                        <MenuItem value={30}>	Rajshahi</MenuItem>
                                   </Select>
                              </FormControl>

                              <FormControl fullWidth sx={{ m: 1, padding: "10px" }}>
                                   <InputLabel htmlFor="outlined-adornment-amount">Phone Number</InputLabel>
                                   <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={' '}
                                        label="Phone Number"
                                        size='small'
                                   />
                              </FormControl>

                              <Button sx={{ width: "100%", maxWidth: '100%', padding: "10px", margin: 2,backgroundColor:"gray",boxShadow:"none",fontWeight:600 }} size='small'  variant='contained' type="submit" fullWidth>Confirm & Order now</Button>

                         </Grid>


                    </Grid>

                    {/* ----------------- part 2------------ */}
                    <Grid
                         sx={{ background: 'transparent', padding: 3, marginTop: "-25px" }}
                         xs={5}
                         container
                         justifyContent="center"
                         alignItems="center"
                    >
                         {/* <h1>part 2</h1> */}

                         <Grid
                              sx={{ background: 'white', padding: 2, borderRadius: "0px" }}
                              direction="column"
                              container
                              justifyContent="center"
                              alignItems="center">

                              <Grid container
                                   justifyContent="flex-start"
                                   alignItems="center">
                                   <label style={{ fontWeight: 'bolder' }}>Order Summary</label>
                                   <p style={{ width: "100%", padding: "2px", margin: '0px',fontSize:12 }}>#1812020158</p>
                              </Grid >


                              <Grid direction="row"

                                   container
                                   container justifyContent="space-between"
                                   alignItems="center">

                                   <Grid >
                                        <p style={{ fontWeight: 'bolder' }}>Items</p> </Grid>
                                   <Grid >
                                        <p>1</p> </Grid>
                              </Grid>

                              <Grid direction="row"
                                   container
                                   container justifyContent="space-between"
                                   alignItems="center">

                                   <Grid >
                                        <p style={{ fontWeight: 'bolder' }}>Total</p>
                                   </Grid>
                                   <Grid >
                                        <p>$500</p>
                                   </Grid>
                              </Grid>


                              {/* <Box
                                sx={{width: "100%", height: 'auto',
                                    backgroundColor: 'primary.dark',
                                    '&:hover': {
                                    backgroundColor: 'primary.main',
                                    opacity: [0.9, 0.8, 0.7],
                                    },
                                }}
                                >
                                    dshfkjshakhkh
                                </Box> */}




                         </Grid>
                         <Grid direction="row"
                              sx={{ background: 'transparent', marginTop: "10px" }}
                              container
                              container justifyContent="space-between"
                              alignItems="center">
                         </Grid>

                         <Grid direction="row"
                              sx={{ background: 'white', padding: 2, borderRadius: "0px",borderBottom:"1px solid #d1d1d1" }}
                              container
                              container justifyContent="space-between"
                              alignItems="center">
                              <label style={{ fontWeight: 'bolder' }}>Order Details</label>

                         </Grid>
                         <Grid direction="row"
                              sx={{ background: 'transparent', marginTop:"0px" }}
                              container
                              container justifyContent="space-between"
                              alignItems="center">
                         </Grid>
                         {/* -----------------------------------item-------------------- */}
                         <Grid xs={12} sx={{ background: 'white', padding: 2, borderRadius: "0px" }}
                              direction='row'
                              container
                              justifyContent="center"
                              alignItems="flex-start"  >

                              <Grid xs={7} container justifyContent="flex-start" alignItems="center"
                              >
                                   <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR29P_8UNL-POgLniwHEmLxL0FFywvDVQn5cw&usqp=CAU'} height='80' width="80px" style={{ borderRadius: "20px" }} />
                              </Grid>

                              <Grid xs={5} direction='row' container justifyContent="flex-start" alignItems="flex-start" >
                                   <Grid xs={12} direction='row' container justifyContent="flex-start" alignItems="flex-start"> <label style={{ fontWeight: 'bolder' }}>T-shirt</label></Grid>
                                   <Grid xs={12} > <p style={{ padding: '0px', margin: '0px', fontSize: '12px' }}>Reg 'XL' in black</p></Grid>
                              </Grid>

                         </Grid>

                    </Grid>

               </Grid>
          </div>
     );
}


const maptstateToprop = (state) => {
     return {
          click_products: state.click_products,
          product_count: state.product_count,
     };
};
const mapdispatchToprop = (dispatch) => {
     return {
          Set_click_products: (val) => dispatch({ type: "click_products", value: val }),
          Set_product_count: (val) => dispatch({ type: "product_count", value: val }),
     };
};
export default connect(maptstateToprop, mapdispatchToprop)(Checkout);