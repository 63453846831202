//https://firebase.google.com/docs/firestore/quickstart#node.js_1
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { db } from "../firebase-config";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, } from "firebase/firestore";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DropDown from "./DropDown"
import menu_submenu from '../../data/menu_submenu';
import ImageUpload from "./05ImageUpload"
class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {name:"",description:"", unit_price:0,discount:0,selected_category: null,
         selected_sub_category: null,image:null };
    }
    addProduct = async () => {
        const productsCollectionRef = collection(db, "products");
        var data = {
            rating: 4.8,
            number_of_people_rated: 30,
            discount: this.state.discount,
            description:this.state.description,
            name: this.state.name,
            category: this.state.selected_category,
            unit_price: this.state.unit_price,
            sub_category: this.state.selected_sub_category,
            image:this.state.image,
        }
        console.log(data,'data');
        await addDoc(productsCollectionRef, data);
    };
    set_selcted_category = (val) => { this.setState({ selected_category: val }); this.setState({ selected_sub_category: null }) }
    set_selcted_sub_category = (val) => this.setState({ selected_sub_category: val })
    set_image=(val) => this.setState({ image: val })
    render() {
        var itemwidth = 300
        return (
            <Grid container direction="column" justifyContent="center" alignItems="center" style={{ width: "100%", height: "100%" }}>
                <Grid item container direction="column" justifyContent="flex-start" alignItems="center" rowSpacing={1} style={{ width: 500, border: "3px solid #c4c4c4" }}>
                    <Grid item style={{ width: itemwidth }}>
                        <ImageUpload set_image={this.set_image} image={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}></ImageUpload>
                    </Grid>
                    <Grid item>
                        <TextField style={{ width: itemwidth }}
                        id="standard-helperText"
                        label="Product Name"
                        defaultValue=""
                        variant="standard"
                        onChange={(event)=>this.setState({name:event.target.value})}
                    />
                    </Grid>
                    <Grid item><TextField style={{ width: itemwidth }}
                        id="outlined-multiline-flexible"
                        multiline
                        maxRows={8}
                        label="Description"
                        defaultValue=""
                        variant="standard"
                        onChange={(event)=>this.setState({description:event.target.value})}
                    /></Grid>
                    <Grid item><TextField style={{ width: itemwidth }}
                        id="standard-helperText"
                        label="Unit Price"
                        defaultValue=""
                        variant="standard"
                        onChange={(event)=>this.setState({unit_price:event.target.value})}
                    />
                    </Grid>
                    <Grid item><TextField style={{ width: itemwidth }}
                        id="standard-helperText"
                        label="Discount"
                        defaultValue=""
                        variant="standard"
                        onChange={(event)=>this.setState({discount:event.target.value})}
                    />
                    </Grid>
                    <Grid item style={{ width: itemwidth }}>
                        <DropDown itemwidth={itemwidth} text="Category" categories={Object.keys(menu_submenu)} set_selcted_category={this.set_selcted_category}></DropDown>
                        {this.state.selected_category != null ? <p>{this.state.selected_category}</p> : null}
                    </Grid>
                    <Grid item style={{ width: itemwidth }}>
                        {this.state.selected_category != null && Object.keys(menu_submenu[this.state.selected_category]).length > 0 ? <DropDown itemwidth={itemwidth} text="Sub Category" categories={Object.keys(menu_submenu[this.state.selected_category])} set_selcted_category={this.set_selcted_sub_category}></DropDown> : null}
                        {this.state.selected_sub_category != null ? <p>{this.state.selected_sub_category}</p> : null}
                    </Grid>

                    <Grid item><Button variant="contained" style={{ width: itemwidth, marginBottom: 20, marginTop: 40 }} onClick={()=>this.addProduct()}> Add </Button></Grid>
                </Grid>
            </Grid>
        )
    }
}
const maptstateToprop = (state) => {
    return {
        products: state.products,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        Set_counter: (val) => dispatch({ type: "counter", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(Products);


