const menu_submenu={
    "Grocery and Essentials": {
        "Milk and Bakery": {},
        "Oil and Ghee": {},
        "Cooking and Baking Needs": {},
        "Cleaning Items": {},
        "Snakes and Confectioners": {},
        "Dog Food": {},
        "Cat Food": {},
        "Fish Food": {},
        "Pet Food": {}
    },

    "Home Appliance": {
        "Washing Machines": {},
        "Microwave Oven": {},
        "Electric Oven and Kettle": {},
        "Rice and Pressure Cooker": {},
        "Blender": {},
        "Mixed and Grinder": {},
        "Coffee Machine": {},
        "Cooling and Heating": {},
        "Fan": {},
        "Cleaners and Mops": {},
        "Irons and Sewing Machine": {}
},

    "Baby Care": {
        "Personal Care": {},
        "Baby Food": {},
        "Baby Diapers": {},
        "Wet Wipes": {},
        "Sanitary Napkins": {},
        "Health Care": {}, 
},

    "Electric and Electronics": {
        "Mobile": {},
        "Mobile Accessories": {},
        "Computer and Laptop": {},
        "Computer Accessories": {},
        "TV": {},
        "Motor Bike": {},
        "Refrigerator": {},
        "AC": {},
        "Car": {}
},

    "Men and Women Fashion": {
        "Women’s Fashion": {},
        "Men’s Fashion": {},
        "Hair Care": {},
        "Skin Care": {},
        "Sexual Wellness": {},
        "Make Up": {},
        "Bath and Body": {},
        "Food Supplements": {}
},

    "Stationery and Office": {
        "Writing Supplies": {},
        "School and Office Equipments": {},
        "Paper Products": {},
        "Art Supplies": {},
        "Gift and Wrapping": {},
},

    "Sports and Gym": {
        "Cricket and Football": {},
        "Basketball and Volleyball": {},
        "Badminton": {},
        "Sports Shoes": {},
        "Sports Bag": {},
        "Accessories": {},
        "Bicycle": {},
        "Treadmills": {},
        "Dumbbells": {},
        "Fitness Accessories": {}
},

    "Medical and Health": {
        "First Aid Supplies": {},
        "Health Accessories": {},
        "Health Monitors and Test": {},
        "Nebulizer and Aspirators": {},
        "Scale and Body Fat Analyzers": {},
        "Injury Support and Braces": {},
        "Medical Tests": {},
        "Surgical Accessories": {}
},
    "Games and Toys": {}
}
export default menu_submenu