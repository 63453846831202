import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid"; 
import Container from '@mui/material/Container'
import Product from "./02Product";
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import Typography from "@mui/material/Typography";
class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temp: "state variable",
      LeftNavigatorOpen: true,
      LoginModalOpen: false,
      productList: [],
    };
  }
  componentDidMount() {
    const productsCollectionRef = collection(db, "products");
    const getProducts = async () => {
      const data = await getDocs(productsCollectionRef);
      var productList = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      this.props.Set_productList(
        productList.filter(
          (item) => item["sub_category"] == this.props.sub_category
        )
      );
    };
    getProducts();
  }
  render() {
    return (
         <div style={{ width: "100%", overflow: "hidden" }}>
              {/* <p> {this.props.MYcounter} </p> */}
              <Typography align="center">
                   <Typography className="intro">
                        <h3> {this.props.sub_category}</h3>
                   </Typography>
              </Typography>

              <Container>
                   <Grid
                        container
                        rowSpacing={1}
                        direction="row"
                        columnSpacing={{ xs: 0.9 }}
                        justifyContent="center"
                        alignItems="center"
                        style={{ padding: 10, marginTop: 20 }}
                   >
                        {this.props.productList.map((item) => (
                             <Grid item xs={3} md={2} lg={2} style={{minWidth:180}}>
                                  <Product product={item}></Product>
                             </Grid>
                        ))}
                   </Grid>
              </Container>
         </div>
    );
  }
}
const maptstateToprop = (state) => {
  return {
    sub_category: state.sub_category,
    productList: state.productList,
    MYcounter:state.counter,
  };
};
const mapdispatchToprop = (dispatch) => {
  return {
    Set_productList: (val) => dispatch({ type: "productList", value: val }),
  };
};
export default connect(maptstateToprop, mapdispatchToprop)(Products);
