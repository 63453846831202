import * as React from 'react';
import { styled, Box } from '@mui/system';
import ModalUnstyled from '@mui/core/ModalUnstyled';
import Checkout from './Checkout';
import Checkout2 from './Checkout2';
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
export default function ModalUnstyledDemo(props) {
  const handleClose = () => {props.set_CheckOutModalOpen(false);props.set_CheckOutModalOpen(false)};
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.CheckOutModalOpen}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
      <Checkout2></Checkout2>
      </StyledModal>
    </div>
  );
}