// import firebase from "firebase"
// import firestore from 'firebase/firestore'

import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyCyiV70KDfjcIYuj2oAR55gQXV5maFKYwc",
  authDomain: "whole-sale-bd.firebaseapp.com",
  projectId: "whole-sale-bd",
  storageBucket: "whole-sale-bd.appspot.com",
  messagingSenderId: "781413745107",
  appId: "1:781413745107:web:31c3abc283147e22a6af26",
  measurementId: "G-GNC6XD9GCQ"
};

 const app = initializeApp(firebaseConfig);

 export const db = getFirestore(app);


 
//  export default db;

// export  firebase;